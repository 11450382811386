import 'bootstrap';

$(document).ready(function() {
  $('.navbar-toggler').on('click', function () {
    $('.navbar-toggler').toggleClass('is-active');
  });

  // $('body').css('paddingTop', $('header').height());

  var header = $('header');
  if(window.location.hash.substr(1) !== ''){
    const anchor = window.location.hash.substr(1)
    if($('#' + anchor).length){
      $('html, body').css({
        scrollTop: $('#' + anchor).offset().top - (header.height()) - 20
      }, 0);
    }
  } else {
    var mobileContent = $('#mobile-content');
    if($(window).width() < 768 && mobileContent.length){
      $('html, body').animate({
        scrollTop: mobileContent.offset().top - (header.height()) - 20
      }, 600);
    }
  }

  if($('#contactbar').length){
    $('body').css('paddingBottom', $('#contactbar').height());
  }

  $('.menu .layer1 a').on('mouseenter', function () {
    var categoryId = $(this).data('category-id');

    $('.menu .layer1 > a').removeClass('active');
    $('.menu .layer2 > div > a').removeClass('active');
    $('.menu .layer3 > div').addClass('d-none');
    $(this).addClass('active');

    $('.menu .layer2 > div').addClass('d-none');
    $('.menu .layer4 > div').addClass('d-none');
    $('#category_' + categoryId).removeClass('d-none');
  });

  $('.menu .layer2 > div a').on('mouseenter', function () {
    var categoryId = $(this).data('category-id');

    $('.menu .layer2 > div > a').removeClass('active');
    $(this).addClass('active');

    $('.menu .layer3 > div').addClass('d-none');
    $('.menu .layer4 > div').addClass('d-none');

    $('#category_' + categoryId).removeClass('d-none');
  });


  $('.menu .layer3 > div a').on('mouseenter', function () {
    var categoryId = $(this).data('category-id');

    $('.menu .layer3 > div > a').removeClass('active');
    $(this).addClass('active');

    $('.menu .layer4 > div').addClass('d-none');

    $('#category_' + categoryId).removeClass('d-none');
  });

  $('.currency-dropdown a').on('click', function () {
    $('.language-dropdown .dropdown-menu').removeClass("show");
  });

  $('.language-dropdown a').on('click', function () {
    $('.currency-dropdown .dropdown-menu').removeClass("show");
  });

  $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function (event) {
    event.preventDefault();
    event.stopPropagation();

    $(this).siblings().toggleClass("show");

    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-submenu').first().find('.show').removeClass("show");
    }

    // $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
    //   $('.dropdown-submenu .show').removeClass("show");
    // });
  });

  $('#hamburger__icon').on('click', function (e) {
    e.preventDefault();
    console.log('hamburger__icon');
    $('body').toggleClass('with--sidebar');
  });

  $('#closeIcon').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('with--sidebar');
    $('.mobile-menu__sub').removeClass('sub-open');
    $('.mobile-menu__nav').removeClass('sub-open');
    $('.mobile-menu__nav ul').removeClass('invisible');
    $('.mobile-menu__sub ul').removeClass('visible');
    $('#hamburger__icon-mobile').removeClass('sub-open');
  });

  $('#hamburger__icon-mobile').on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('sub-open')) {
      $('.mobile-menu__sub').removeClass('sub-open');
      $('.mobile-menu__nav').removeClass('sub-open');
      $('#hamburger__icon-mobile').removeClass('sub-open');
      $('.mobile-menu__nav ul').removeClass('invisible');
      $('.mobile-menu__sub').removeClass('visible');
      scrollTo();
    } else {
      $('body').toggleClass('with--sidebar');
    }
  });

  $('.mobile-menu__nav ul li').on('click', function () {
    $(this).find('.mobile-menu__sub').toggleClass('sub-open');
    $('.mobile-menu__nav').toggleClass('sub-open');
    $('.mobile-menu__nav ul').toggleClass('invisible');
    $('.mobile-menu__sub ul').removeClass('invisible').addClass('visible');
    $('#hamburger__icon-mobile').toggleClass('sub-open');
    scrollTo();
  });

  $('.logged-in').on('mouseenter', function(){
    $('.logged-in').addClass('show');
    $('.profile__dropdown').addClass('show');
  });

  $('.logged-in').on('mouseleave', function(){
    $('.logged-in').removeClass('show');
    $('.profile__dropdown').removeClass('show');
  });

  $(document).on('click', '.dropdown', function (e) {
    e.stopPropagation();
  });

  function scrollTo(){
    $("#mobile-menu__top").get(0).scrollTo(0, 0)
  }
});
